
import { mapGetters, mapActions } from 'vuex'
import { notFound } from '../services/not_found'
export default {
  name: 'HomePage',
  components: {
    LocationPopup: () => import('../components/LocationPopup.vue'),
    BCU: () => import('../components/BCU.vue'),
    SearchExchange: () => import('../components/SearchExchange.vue'),
  },
  async middleware({ store, redirect, $axios, $i18n, query }) {},
  data() {
    return {
      hiddenWidgets: false,
      hasScroll: false,
      all_items: [],
      snackbar: false,
      snackBarText: '',
      loadingDistances: false,
      onlyInterbank: ['UR', 'UP'],
      location: 'TODOS',
      texts: {
        es: {
          USD: 'Dólares estadounidenses',
          ARS: 'Pesos Argentinos',
          BRL: 'Reales Brasileños',
          EUR: 'Euros',
          GBP: 'Libras Esterlinas',
          XAU: 'Oro',
          UR: 'Unidades Reajustables',
          UP: 'Unidad Previsional',
          UI: 'Unidades Indexadas',
          PYG: 'Guaraníes Paraguayos',
          PEN: 'Soles Peruanos',
          MXP: 'Pesos Mexicanos',
          JPY: 'Yenes',
          CLP: 'Pesos Chilenos',
          CHF: 'Francos Suizos',
          CAD: 'Dólares Canadienses',
          AUD: 'Dólares Australianos',
          UYU: 'Pesos Uruguayos',
        },
        pt: {
          USD: 'Dólares dos Estados Unidos',
          ARS: 'Pesos Argentinos',
          BRL: 'Reais Brasileiros',
          EUR: 'Euros',
          GBP: 'Libras Esterlinas Britânicas)',
          XAU: 'Ouro',
          UR: 'Unidades reajustáveis',
          UP: 'Unidade Previsional',
          UI: 'Unidades indexadas',
          PYG: 'Guaranis paraguaios',
          PEN: 'Soles peruanos',
          MXP: 'Pesos Mexicanos',
          JPY: 'iene japonês',
          CLP: 'Pesos chilenos',
          CHF: 'Francos suíços',
          CAD: 'Dólares canadenses',
          AUD: 'Dólares australianos',
          UYU: 'Pesos Uruguayos',
        },
        en: {
          USD: 'United States Dollars',
          ARS: 'Argentine Pesos',
          BRL: 'Brazilian Reais',
          EUR: 'Euros',
          GBP: 'British Pounds Sterling',
          XAU: 'Gold',
          UR: 'Readjustable Units',
          UP: 'Unidade Previsional',
          UI: 'Indexed Units',
          PYG: 'Paraguayan Guaraníes',
          PEN: 'Peruvian Soles',
          MXP: 'Mexican Pesos',
          JPY: 'Japanese Yen',
          CLP: 'Chilean Pesos',
          CHF: 'Swiss Francs',
          CAD: 'Canadian Dollars',
          AUD: 'Australian Dollars',
          UYU: 'Pesos Uruguayos',
        },
      },
      money: ['USD', 'ARS', 'BRL', 'EUR', 'GBP', 'UYU'],
      amount: 100,
      show_install: false,
      wantTo: 'buy',
      notConditional: false,
      day: new Date().toLocaleDateString(),
      code: '',
      code_with: '',
      notInterBank: true,
      items: [],
      enableDistance: false,
      latitude: 0,
      longitude: 0,
      no_distance: 9999999,
      lastPos: undefined,
      scrollWidth: 0,
    }
  },
  head() {
    return this.$nuxtI18nHead({
      addSeoAttributes: true,
    })
  },
  computed: {
    ...mapGetters({
      allItems: 'all_items',
      locations: 'locations',
      fortex: 'fortex',
    }),
    onlyInterBank() {
      return this.onlyInterbank.includes(this.code)
    },
  },
  beforeMount() {
    this.beforeMount()
  },
  mounted() {
    ;(window as any).startLoading = () => {
      const el = document.getElementById('spinner-wrapper')
      if (el) el.style.display = 'flex'
    }
    ;(window as any).stopLoading = () => {
      const el = document.getElementById('spinner-wrapper')
      if (el) el.style.display = 'none'
    }
    this.setScrollBar()
  },
  methods: {
    async setup() {
      const locations = ['TODOS', 'MONTEVIDEO']
      const localData = await this.$axios
        .get('https://api.cambio-uruguay.com/localData')
        .then((res) => res.data)
      console.log('Local Data Response', localData)
      for (const key in localData) {
        const val = localData[key]
        const departments = val.departments
        if (departments && departments.length) {
          for (const dep of departments) {
            if (!locations.includes(dep)) {
              locations.push(dep)
            }
          }
        }
      }

      const getCondition = (el) => {
        if (el.origin === 'prex') {
          return 'prex_condition'
        }
        if (el.type === 'EBROU') {
          return 'ebrou_condition'
        }
        return ''
      }

      const isInterBank = (item: any) => {
        return (
          item.origin === 'bcu' ||
          ['INTERBANCARIO', 'FONDO/CABLE'].includes(item.type)
        )
      }

      const dataFortex = await this.$axios
        .get('https://api.cambio-uruguay.com/fortex')
        .then((res) => res.data)
      this.$store.dispatch('setFortex', dataFortex)

      const data = await this.$axios
        .get('https://api.cambio-uruguay.com')
        .then((res) =>
          (res.data as any[])
            .map((el: any) => {
              el.localData = localData[el.origin]
              if (!el.localData) {
                el.localData = null
              }
              el.isInterBank = isInterBank(el)
              el.condition = getCondition(el)
              return el
            })
            .filter((el: any) => el.localData)
        )
      this.$store.dispatch('setLocations', locations)
      this.$store.dispatch('setItems', data)
    },
    changeCode(code: string, codeWith: string) {
      this.code = codeWith
      this.code_with = code
      this.updateTable()
    },
    formatNumber(number: number) {
      const nString = number.toString()
      if (!nString.includes('.')) {
        return number.toFixed(2)
      }
      const n = number.toPrecision(2)
      const nSplit = nString.split('.')[1]
      if (nSplit.length <= 2) {
        return number.toFixed(2)
      }
      return n
    },
    hideFeedback() {
      document.head.insertAdjacentHTML(
        'beforeend',
        `<style type="text/css" class="custom_style_list">
                    ._hj_feedback_container {
                      display:none!important;
                    }
            </style>`
      )
    },
    hideWidgets(val: boolean, att = 0) {
      const t = (window as any).Tawk_API
      if (t && t.hideWidget) {
        if (val) {
          localStorage.setItem('hideWidgets', '1')
          t.hideWidget()
          this.hideFeedback()
        } else {
          localStorage.removeItem('hideWidgets')
          t.showWidget()
          const el = document.querySelector('.custom_style_list')
          if (el) el.remove()
        }
      } else {
        this.$nextTick(() => {
          att++
          if (att === 10) {
            console.log('hide widget', att)
            return
          }
          this.hideWidgets(val, att)
        })
      }
    },
    async beforeMount() {
      await this.setup()
      this.all_items = [...this.allItems]
      let pwaInstall = false
      try {
        if (!window.matchMedia('(display-mode: standalone)').matches) {
          pwaInstall = true
        }
      } catch (e) {
        console.error(e)
      }
      if (pwaInstall) {
        ;(window as any).deferredPrompt = null
        window.addEventListener('beforeinstallprompt', (e) => {
          ;(window as any).deferredPrompt = e
          if (e !== null) {
            this.show_install = true
          }
        })
      }
      if (this.$route.query.notConditional) {
        this.notConditional = true
      }
      if (this.$route.query.notInterBank) {
        this.notInterBank = true
      }
      this.amount = this.$route.query.amount
        ? parseFloat(this.$route.query.amount)
        : 100
      this.wantTo = this.$route.query.wantTo ? this.$route.query.wantTo : 'buy'
      this.code = this.$route.query.currency
        ? this.$route.query.currency
        : 'USD'
      this.location = this.$route.query.location
        ? this.$route.query.location
        : 'TODOS'
      this.code_with = this.$route.query.currency_with
        ? this.$route.query.currency_with
        : 'UYU'
      this.get_data()

      if (localStorage.getItem('hideWidgets') === '1') {
        this.hiddenWidgets = true
        ;(window as any).Tawk_API.onLoad = () => {
          ;(window as any).Tawk_API.hideWidget()
        }
        this.hideFeedback()
      }
    },
    plusUy(array: string[]) {
      return [...array.filter((el) => el !== this.code), 'UYU']
    },
    setScrollBar() {
      const tableWrapper = document.querySelector(
        '.money_table .v-data-table__wrapper'
      )
      if (!tableWrapper) {
        this.$nextTick(() => {
          this.setScrollBar()
        })
        return
      }
      // Check if resolution is mobile.
      const isMobile = document.querySelector(
        '.money_table.v-data-table--mobile'
      )
      this.hasScroll = tableWrapper.scrollWidth > tableWrapper.clientWidth
      let wp1 = null
      let wp2 = null
      let wrapper1 = null
      let wrapper2 = null
      if (this.hasScroll && !isMobile) {
        wrapper1 = document.querySelector('.money_table .v-data-table__wrapper')
        wrapper2 = this.$refs.wrapper2
        if (!wrapper2 || !wrapper1) {
          this.$nextTick(() => {
            this.setScrollBar()
          })
          return
        }

        const table = document.querySelector('.money_table table')

        this.scrollWidth = table.clientWidth + 10 + 'px'

        let scrolling = false
        wp1 = function () {
          if (scrolling) {
            scrolling = false
            return true
          }
          scrolling = true

          wrapper2.scrollLeft = wrapper1.scrollLeft
        }

        wp2 = function () {
          if (scrolling) {
            scrolling = false
            return true
          }
          scrolling = true
          wrapper1.scrollLeft = wrapper2.scrollLeft
        }

        wrapper1.addEventListener('scroll', wp1)
        wrapper2.addEventListener('scroll', wp2)
      }

      addEventListener(
        'resize',
        () => {
          if (wrapper1) {
            wrapper1.removeEventListener('scroll', wp1)
            wrapper2.removeEventListener('scroll', wp2)
          }
          this.setScrollBar()
        },
        { once: true }
      )
    },
    getTexts(data: any) {
      return this.texts[this.$i18n.locale][data.item]
    },
    getDistanceLink({ distanceData, localData, origin }) {
      if (distanceData) {
        const { latitude, longitude, map } = distanceData
        if (map) return map
        if (!notFound.includes(origin)) {
          return `https://www.google.com.uy/maps/search/${encodeURI(
            localData.name
          )}/@${latitude},${longitude},18.77z`
        } else {
          return `https://www.google.com.uy/maps/search/${latitude},${longitude}`
        }
      }
    },
    fixTitle(text: string) {
      return text.replace('{{day}}', this.day)
    },
    geoLocationSuccess(
      distances: any,
      latitude: number,
      longitude: number,
      distanceData: any,
      radius: number
    ) {
      this.latitude = latitude
      this.longitude = longitude
      let allItems = this.all_items.map((item: any) => {
        item.distance = distances[item.origin]
          ? distances[item.origin]
          : this.no_distance
        if (item.distance !== this.no_distance) {
          item.distanceData = distanceData[item.distance]
        }
        return item
      })
      if (radius) {
        allItems = allItems.filter((item: any) => item.distance <= radius)
      }
      this.all_items = allItems
      this.updateTable()
      this.enableDistance = true
      this.snackbar = true
      this.snackBarText = 'Distancias cargadas correctamente'
      setTimeout(() => {
        this.snackbar = false
      }, 1500)
    },
    undoDistances() {
      this.latitude = 0
      this.longitude = 0
      this.enableDistance = false
      this.all_items = [...this.allItems]
      this.updateTable()
    },
    formatDistance(item: number) {
      if (item >= 1000) {
        return Math.round(item / 1000.0) + ' km'
      } else if (item >= 100) {
        return Math.round(item) + ' m'
      } else {
        return item.toFixed(1) + ' m'
      }
    },
    capitalize(entry: string) {
      let str = entry
      if (entry === 'TODOS') {
        const locale = this.$i18n.locale
        const tr = {
          es: 'TODOS',
          en: 'ALL',
          pt: 'TODOS',
        }
        str = tr[locale]
      }
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
    },
    getHeaders() {
      const toReturn = [
        {
          text: 'Rank',
          value: 'pos',
          width: 'auto',
        },
        {
          text: this.wantTo === 'buy' ? this.$t('pagas') : this.$t('recibes'),
          value: 'amount',
          width: 'auto',
        },
        {
          text: this.$t('moneda'),
          align: 'start',
          sortable: false,
          width: '180px',
          value: 'code',
        },
        { text: this.$t('casaDeCambio'), value: 'localData.name' },
        { text: this.$t('compra') + ` (${this.code_with})`, value: 'buy' },
        { text: this.$t('venta') + ` (${this.code_with})`, value: 'sell' },
        { text: 'Dif (%)', value: 'diff' },
        {
          text: this.$t('sitioWeb'),
          value: 'localData.website',
          sortable: false,
          width: 'auto',
        },
        {
          text: this.$t('buscarSucursal'),
          value: 'localData.location',
          sortable: false,
        },
        { text: this.$t('condicional'), value: 'condition', width: '250px' },
        { text: 'BCU', value: 'localData.bcu', width: '50px' },
      ]
      if (this.enableDistance) {
        toReturn.push({
          text: this.$t('distancia'),
          value: 'distance',
          width: 'auto',
        })
      }
      return toReturn
    },
    savings() {
      if (!this.items.length) return
      const i = this.items
      const key = this.wantTo === 'buy' ? 'sell' : 'buy'
      const maxValue = i[0][key]
      const minValue = i[i.length - 1][key]
      let savePercent = 0
      if (key === 'buy') {
        savePercent = ((maxValue - minValue) / minValue) * 100
      } else {
        savePercent = ((minValue - maxValue) / maxValue) * 100
      }
      const saveAmount =
        Math.abs((maxValue - minValue) * this.amount).toFixed(2) +
        ' ' +
        this.code_with
      const s = savePercent.toFixed(2)
      const loc = {
        es: `Puedes ahorrar hasta un ${s}% (${saveAmount}) utilizando nuestra app.`,
        en: `You can save up to ${s}% (${saveAmount}) by using our app`,
        pt: `Você pode economizar até ${s}% (${saveAmount}) ao utilizar nosso aplicativo`,
      }
      return loc[this.$i18n.locale]
    },
    share() {
      let text = this.buy ? `${this.$t('sell')} ` : `${this.$t('buy')} `
      if (this.texts[this.$i18n.locale][this.code]) {
        text +=
          this.texts[this.$i18n.locale][this.code].toLowerCase() +
          ' ' +
          '(' +
          this.code +
          ')'
      } else {
        text += this.code
      }
      let extra = ''
      if (this.location !== 'TODOS') {
        extra += this.$t('from') + this.capitalize(this.location) + ' '
      }
      const loc = {
        es: 'Estas son las mejores entidades uruguayas',
        en: 'These are the best Uruguayan entities',
        pt: 'Estas são as melhores entidades uruguaias',
      }
      const finalText =
        loc[this.$i18n.locale] + ' ' + extra + this.$t('to') + ' ' + text
      try {
        navigator.share({
          url: window.location.href,
          text: finalText,
          title: 'Cambio Uruguay',
        })
      } catch (e) {
        console.error(e)
      }
    },
    async install_app() {
      const deferredPrompt = (window as any).deferredPrompt
      if (deferredPrompt) {
        deferredPrompt.prompt()
        const { outcome } = await deferredPrompt.userChoice
        if (outcome === 'accepted') {
          ;(window as any).deferredPrompt = null
        }
      } else if (this.$refs.pwa_open) {
        this.$refs.pwa_open.click()
      }
    },
    get_text() {
      if (!this.items.length) return
      const m = this.formatMoney(this.items[0].amount)
      if (this.wantTo === 'buy') {
        const loc = {
          es: `Comprar ${this.amount} ${this.code} te costará un total de ${m}.`,
          en: `Buying ${this.amount} ${this.code} will cost you a total of ${m}.`,
          pt: `Comprar ${this.amount} ${this.code} lhe custará um total de ${m}.`,
        }
        return loc[this.$i18n.locale]
      } else {
        const loc = {
          es: `Te darán ${m} por tus ${this.amount} ${this.code}.`,
          en: `You will receive ${m} for your ${this.amount} ${this.code}.`,
          pt: `Você receberá ${m} por seus ${this.amount} ${this.code}.`,
        }
        return loc[this.$i18n.locale]
      }
    },
    getColor({ pos }) {
      if (this.amount === 0) return ''
      if (pos === 1) return 'green darken-4'
      if (pos === this.lastPos) return 'red darken-4'
      return ''
    },
    formatMoney(number) {
      return number.toLocaleString('es-ES', {
        style: 'currency',
        currency: this.code_with,
      })
    },
    row_classes(item) {
      if (item.isInterBank) {
        return 'purple darken-4'
      }
      if (item.condition) {
        return 'grey darken-3'
      }
      return ''
    },
    updateTable() {
      if (this.code === 'UYU') {
        this.items = this.all_items.filter(
          (el) =>
            (!this.code || el.code === this.code_with) &&
            (!this.notInterBank ||
              !el.isInterBank ||
              this.onlyInterbank.includes(this.code)) &&
            (!this.notConditional || !el.condition) &&
            (this.location === 'TODOS' ||
              !el.localData.departments.length ||
              el.localData.departments.includes(this.location))
        )
      } else {
        this.items = this.all_items.filter(
          (el) =>
            (!this.code || el.code === this.code) &&
            (!this.notInterBank ||
              !el.isInterBank ||
              this.onlyInterbank.includes(this.code)) &&
            (!this.notConditional || !el.condition) &&
            (this.location === 'TODOS' ||
              !el.localData.departments.length ||
              el.localData.departments.includes(this.location))
        )
      }
      if (this.code_with && this.code_with !== 'UYU') {
        const codeOrigins: any = {}
        this.items = this.items
          .filter((el) => {
            if (this.code === 'UYU') return true
            const f = this.all_items.find(
              (e) =>
                e.origin === el.origin &&
                e.code === this.code_with &&
                e.type === el.type
            )
            codeOrigins[el.origin + (el.type ? el.type : '')] = f
            return f !== undefined
          })
          .map((e) => {
            const el = { ...e }
            if (this.code === 'UYU') {
              el.sell = 1 / e.buy
              el.buy = 1 / e.sell
            } else if (el.origin === 'fortex') {
              if (
                (this.code_with === 'ARS' && this.code === 'USD') ||
                (this.code === 'ARS' && this.code_with === 'USD')
              ) {
                el.sell = this.fortex[this.code_with][this.code]
              } else {
                el.sell = 1 / this.fortex[this.code_with][this.code]
              }
              el.buy = this.fortex[this.code][this.code_with]
            } else {
              const f = codeOrigins[el.origin + (el.type ? el.type : '')]
              el.sell = e.sell / f.buy
              el.buy = e.buy / f.sell
            }
            return el
          })
      }
      this.setPrice()
    },
    setPrice() {
      if (this.amount < 0) {
        this.amount = 0
      }
      const query: any = {
        currency: this.code,
        amount: this.amount,
        wantTo: this.wantTo,
        location: this.location,
        currency_with: this.code_with,
        notInterBank: this.notInterBank ? 1 : undefined,
        notConditional: this.notConditional ? 1 : undefined,
      }
      // Change route.
      this.$router.push({
        ...this.$route.query,
        query,
      })
      const amount = this.amount
      const wanToSell = this.wantTo === 'sell'
      this.items.sort((a, b) => {
        if (wanToSell) {
          if (b.buy === a.buy) return 0
          return b.buy <= a.buy ? -1 : 1
        }
        if (b.sell === a.sell) return 0
        return b.sell <= a.sell ? 1 : -1
      })
      let pos = 1
      this.items = (this.items as any[]).map((el, index, arr) => {
        if (index !== 0) {
          if (wanToSell) {
            if (el.buy !== arr[index - 1].buy) {
              pos++
            }
          } else if (el.sell !== arr[index - 1].sell) {
            pos++
          }
        }
        el.pos = pos
        const sell = amount * el.buy
        const buy = amount * el.sell
        el.amount = wanToSell ? sell : buy
        el.diff = (((buy - sell) / sell) * 100).toFixed(2)
        return el
      })
      this.lastPos = pos
    },
    finishLoading() {
      this.$nextTick(() => {
        const el = document.getElementById('spinner-wrapper')
        if (el) el.style.display = 'none'
        else {
          this.finishLoading()
        }
      })
    },
    get_data() {
      this.all_items.forEach(({ code }) => {
        if (!this.money.includes(code)) {
          this.money.push(code)
        }
      })
      this.updateTable()
      this.finishLoading()
    },
  },
}
